<script lang="ts">
  import { ImageOptimized } from "@fxwrapr/web-common";
  import { type Options, Splide, SplideSlide } from "@splidejs/svelte-splide";
  import "@splidejs/svelte-splide/css";

  export let partners: { src: string; alt: string }[];

  const options: Options = {
    type: "loop",
    focus: "center",
    fixedWidth: "200px",
    fixedHeight: "264px",
    gap: "2em",
    autoWidth: true,
    autoplay: true,
  };
</script>

<Splide class="mx-auto" {options}>
  {#each partners as { src, alt }}
    <SplideSlide class="flex-center">
      <ImageOptimized {src} {alt} densities={[1, 1.5, 2]} width="200" height="200" />
    </SplideSlide>
  {/each}
</Splide>
